<template>
  <div :class="className" :style="{ width: width, height: height, margin: '0 auto', paddingRight: '2vw' }" />
</template>

<script>
  import echarts from "echarts";

  export default {
    props: {
      className: {
        type: String,
        default: "chart",
      },
      width: {
        type: String,
        default: "96%",
      },
      height: {
        type: String,
        // default: 'calc(100% - 30px)'
        default: "18vh",
      },
      autoResize: {
        type: Boolean,
        default: true,
      },
      sosPieData: {
        type: Array,
        default: () => [{
            value: 0,
            name: '维保'
          },
          {
            value: 0,
            name: '救援'
          },
          {
            value: 0,
            name: '理赔'
          },
          {
            value: 0,
            name: '维修'
          }
        ],
      },
    },

    data() {
      return {
        chart: null,
      };
    },

    // 监听data里面对应的
    watch: {
      data: {
        deep: true,
        handler(val) {
          this.setOptions(val);
        },
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.initChart();
      });
    },

    beforeDestroy() {
      if (!this.chart) return;
      // 释放图表实例
      this.chart.dispose();
      this.chart = null;
    },

    methods: {
      initChart() {
        this.chart = this.$echarts.init(this.$el);
        this.setOptions(this.sosPieData);
      },
      attackSourcesDataFmt(sData, type) {
        console.log(sData, '时分秒')


        var sss = sData.sort(function(a, b) {
          return b.value - a.value
        })
        let data1 = []
        let data2 = []
        console.log(type)
        sss.forEach((item) => {
          data1.push(item.name)
          data2.push(Number(item.value) > 0 ? (item.value/1000 /60).toFixed(0) : '0')
        })

        if (type == 1) {
          console.log(data1, data2, type, 'data')
          return data1
        } else {
          console.log(data1, data2, type, 'data')
          return data2
        }
        // console.log(sss, 'sss')
        // var sss= sData.sort(function(a, b) {
        //   return a.value - b.value
        // });
        // return data;
        // console.log(sss, 'sss')
      },
      setOptions(data) {
        // console.log(data, 'data')
        const options = {
          title: {
            // text: 'World Population'
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            },
            formatter: function(params) {
              // console.log(params,'params')
              // 自定义提示框内容
              let result=''
              params.forEach((item)=>{
                result = item.name + '<br/>';
                result += item.marker + item.seriesName + ': ' + item.value + '分钟' + '<br/>';
              })
              // var result = params.name + '<br/>';
              // result += params.marker + params.seriesName + ': ' + params.value + '次' + '<br/>';
              // });
              return result;
            }
          },
          // legend: {
          //   textStyle: {
          //   	color: '#409eff' //字体颜色
          //   },
          // },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01],
            axisLine: {
              lineStyle: {
                color: '#4dc1ed'

              }
            },
          },
          yAxis: {
            inverse: true,
            type: 'category',
            data: this.attackSourcesDataFmt(data, 1),
            axisLine: {
              lineStyle: {
                color: '#4dc1ed'

              }
            },
          },
          series: [{
            sort: false,
            name: '平均用时',
            type: 'bar',
            data: this.attackSourcesDataFmt(data, 2),
            itemStyle: {
              color: '#1986ea' // 设置统一的颜色
            }
          }]
        }
        this.chart.setOption(options);
      },
    },
  };
</script>
